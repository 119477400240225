import Intro1 from '../intro/intro1';
import Intro3 from '../intro/intro3';
import Intro4 from '../intro/intro4';
import KnowledgeCheck, { Quiz } from '../intro/KnowledgeCheck';
import STACTransition from './STACTransition';
import React from 'react';
import StudentsWhoBully from '../bullying/studentsWhoBully';
import data from '../../../assets/json/modalResponses.json';
import Intro5 from '../intro/intro5';
import RankedDnd from '../bullying/RankedDnD/Index';
import Intro2 from '../intro/intro2';
import BullyTypeSelector from '../bullying/bullyTypeSelector';
import BullyFacts2 from '../bullying/bullyFacts2';
import WriteItOut from '../bullying/WriteItOut';
import Selector from '../bystanders/bystandSelector';
import ActivityModule from '../bystanders/Activity/ActivityModule';
import DefenderFacts from '../bystanders/defendersFacts';
import { v4 as uuidv4 } from 'uuid';
import Steal from '../stac/steal';
import WhosWho from '../intro/WhosWho';
import Turn from '../stac/turn';
import WhoToTurnTo2 from '../intro/WhoToTurnTo2';
import Accompany from '../stac/accompany';
import Coach from '../stac/coach';
import AllAvatars from '../intro/AllAvatars';
import AvatarSlider from './AvatarSlider';
import AvatarFirst from './AvatarFirst';
import AvatarFourth from './AvatarFourth';
import EndSlide from './EndSlide';
import AvatarSecond from './AvatarSecond';
import AvatarThird from './AvatarThird';
import { autoplayEnum } from '../../../hooks/zustand';
import Certificate from './Certificate';
import SAPPS, { SAPPSWithTeacher } from './SAPPS';
import Title from '../../shared/Title';
import { AvatarIntro, PhysicalIntro } from './AvatarIntro';
import { Congrats } from './Congrats';

const matchingActivity1 = [
  {
    Person: 'Grace',
    Role: 'Target',
    correctAnswer: 'Correct!',
    incorrectAnswer: 'Grace is the one who is being bullied. Try again.',
  },
  {
    Person: 'Brenna',
    Role: 'Bully',
    correctAnswer: 'Correct!',
    incorrectAnswer:
      'Brenna is the one who initiated targeting Grace. Try again.',
  },
  {
    Person: 'Keira',
    Role: 'Assistant',
    correctAnswer: 'Correct! Keira is helping the bully.',
    incorrectAnswer:
      'Keira is a type of bystander who is helping the bully. Try again.',
  },
  {
    Person: 'Ty',
    Role: 'Reinforcer',
    correctAnswer: 'Correct! Ty is supporting the bully by laughing.',
    incorrectAnswer:
      'Ty is a type of bystander who is not directly involved but is supporting the bully. Try again.',
  },
  {
    Person: 'Other students on the bus',
    Role: 'Outsiders',
    correctAnswer:
      'Correct! The other students aren’t getting involved but they know what’s happening.',
    incorrectAnswer:
      'The other students aren’t getting involved but they know what’s going on. Try again.',
  },
].map((item) => ({ ...item, id: uuidv4() }));

const knowledgeCheckMod4 = {
  prompt: 'Mark each item as true or false.',
  options: ['true', 'false'],
  questions: [
    {
      type: 'true',
      description:
        'A defender can Steal the Show by distracting the bully by acting silly or starting a conversation.',
      answer: 'not answered',
    },
    {
      type: 'false',
      description:
        'Defenders should use Stealing the Show and get involved if they see a physical fight.',
      answer: 'not answered',
    },
    {
      type: 'true',
      description:
        'Defenders can use Accompanying Others by inviting a classmate who was bullied to have lunch together.',
      answer: 'not answered',
    },
    {
      type: 'false',
      description:
        'It is appropriate to Turn Over a bullying situation to another student at school.',
      answer: 'not answered',
    },
    {
      type: 'true',
      description:
        'Defenders could use Coaching Compassion if they are older or good friends with the bully or think the bully will respect them.',
      answer: 'not answered',
    },
  ],
};

const knowledgeCheckMod2 = {
  prompt: 'Match the description with the Bystander Role.',
  options: ['Defender', 'Outsider', 'Assistant', 'Reinforcer'],
  questions: [
    {
      type: 'Defender',
      description:
        'Becomes friends with or offers support to a student who is a target of bullying.',
      answer: 'not answered',
    },
    {
      type: 'Outsider',
      description:
        'Sees bullying happen but walks away and does not get involved.',
      answer: 'not answered',
    },
    {
      type: 'Assistant',
      description: 'Helps the bully and joins in on making fun of the target.',
      answer: 'not answered',
    },
    {
      type: 'Reinforcer',
      description:
        'Cheers and laughs while watching the bully make fun of the target.',
      answer: 'not answered',
    },
  ],
};

const knowledgeCheckMod1 = {
  prompt: 'Is this an example of bullying?',
  options: ['Yes', 'No'],
  questions: [
    {
      type: 'Yes',
      description: 'Spreading rumors',
      answer: 'not answered',
      inccorectResponse:
        "Spreading rumors about someone is a form of relationship bullying because it is intended to hurt someone's reputation or leave someone out on purpose. Try Again!",
      audio: {
        question: '/audio/knowledge-check-1-1/q1.mp3',
        wrong: '/audio/knowledge-check-1-1/q1-wrong.mp3',
      },
    },
    {
      type: 'No',
      description:
        'Yelling someone’s name in the hallway to get your classmate’s attention',
      answer: 'not answered',
      inccorectResponse:
        "Yelling someone's name to get a student's attention does not have a mean intention behind it, and it is not considered verbal bullying. Try Again!",
      audio: {
        question: '/audio/knowledge-check-1-1/q2.mp3',
        wrong: '/audio/knowledge-check-1-1/q2-wrong.mp3',
      },
    },
    {
      type: 'Yes',
      description:
        'Posting embarrassing pictures of someone on social media using a fake profile',
      answer: 'not answered',
      inccorectResponse:
        'This is a form of cyberbullying because the intention is to embarrass or make fun of a peer. Try Again!',
      audio: {
        question: '/audio/knowledge-check-1-1/q3.mp3',
        wrong: '/audio/knowledge-check-1-1/q3-wrong.mp3',
      },
    },
    {
      type: 'No',
      description:
        'Pretend fighting among students who are in the same friend group',
      answer: 'not answered',
      inccorectResponse:
        'If students are able to walk away, it is not considered physical bullying because there is no power differential among the students. Try Again!',
      audio: {
        question: '/audio/knowledge-check-1-1/q4.mp3',
        wrong: '/audio/knowledge-check-1-1/q4-wrong.mp3',
      },
    },
  ],
};

const knowledgeCheckMod12 = {
  prompt: 'Match the examples below with the 4 different types of bullying.',
  options: [
    'Verbal Bullying',
    'Physical Bullying',
    'Cyberbullying',
    'Relationship Bullying',
  ],
  questions: [
    {
      type: 'Verbal Bullying',
      description:
        'Saying mean things to someone every day as the student walks by in the hallway',
      answer: 'not answered',
      audio: {
        question: '/audio/knowledge-check-1-2/q1.mp3',
      },
    },
    {
      type: 'Cyberbullying',
      description:
        'Texting an embarrassing photo of a classmate to other kids at school with the intention of humiliating that student',
      answer: 'not answered',
      audio: {
        question: '/audio/knowledge-check-1-2/q2.mp3',
      },
    },
    {
      type: 'Physical Bullying',
      description:
        'Backpack or shoulder-checking by pushing a student into a locker on purpose',
      answer: 'not answered',
      audio: {
        question: '/audio/knowledge-check-1-2/q3.mp3',
      },
    },
    {
      type: 'Relationship Bullying',
      description:
        'Leaving someone out of activities during break to make them feel bad and spreading a rumor about the student',
      answer: 'not answered',
      audio: {
        question: '/audio/knowledge-check-1-2/q4.mp3',
      },
    },
  ],
};

const beReady = [
  "I saw what happened. That wasn't ok.",
  "That wasn't cool.",
  'Come eat lunch with us today.',
  "I'll walk with you. I am heading that way too.",
  'That was not right.',
  'I am here for you if you want to talk or need someone to hang out with.',
  "It's going to be okay. They are not worth it.",
  'I got your back.',
];

const whatCanISay = [
  'It’s not cool to make fun of people.',
  'Dude, that was bullying.',
  'Knock it off.',
  'That was not right.',
  'Stop! That’s enough.',
  'Stop and think about if that was you back there.',
  'Really? You know that was wrong.',
];

export const sectionEnum = {
  whatisBullying: 'What is Bullying?',
  bystanderRoles: 'Bystander Roles',
  stacStrategies: 'STAC Strategies',
  wwyd: 'What Would You Do?',
};

const sappSurvey = {
  prompt:
    'Please read each statement and circle the answer that matches how much you agree with each statement. There are no right or wrong answers so please share your honest feelings.  ',
  options: ['I Totally Agree', 'I Agree', 'I Disagree', 'I Totally Disagree'],
  questions: [
    {
      description: '1. I know what verbal bullying looks like.',
      answer: null,
    },

    {
      description: '2. I know what relationship bullying looks like.',
      answer: null,
    },
    {
      description: '3. I know what physical bullying looks like.',
      answer: null,
    },
    {
      description: '4. I know what cyberbullying looks like.',
      answer: null,
    },
    {
      description:
        '5. I know how to use humor to get attention away from the student being bullied. ',
      answer: null,
    },
    {
      description:
        '6. I know how to ask for help from an adult and report bullying at my school.',
      answer: null,
    },
    {
      description: '7. I know how to reach out to the student being bullied.',
      answer: null,
    },
    {
      description:
        '8. I know how to gently confront the bully and offer suggestions to help him or her develop empathy for the target of bullying. ',
      answer: null,
    },
    {
      description:
        '9. I feel confident in my ability to do something helpful to decrease bullying at my school.',
      answer: null,
    },
    {
      description:
        '10. I feel comfortable being an advocate to stop bullying at my school.',
      answer: null,
    },
    {
      description:
        '11. I feel like I can make a positive difference against bullying at my school.',
      answer: null,
    },
  ],
};

//audio types can be bullet, teacher, title, or slideshow (for the avatar slides)

export const countArray = [
  {
    url: 'bullying-facts',
    component: <Intro2 />,
    section: sectionEnum.whatisBullying,
    locked: true,
    autoplay: autoplayEnum.ALL,
    audio: [
      {
        audio: '/audio/bullying-facts/1.mp3',
        type: 'title',
      },
    ],
  },
  {
    url: 'what-is-bullying',
    component: <Intro3 />,
    section: sectionEnum.whatisBullying,
    locked: true,
    autoplay: autoplayEnum.ALL,
    audio: [
      {
        audio: '/audio/what-is-bullying/1.mp3',
        type: 'title',
      },
      {
        audio: '/audio/what-is-bullying/2.mp3',
        text: 'Bullying is repeated, aggressive behavior (or rude behavior) by one student or a group of students.',
        type: 'bullet',
      },
      {
        audio: '/audio/what-is-bullying/3.mp3',
        text: 'The student who is the target of bullying does not want this to be happening, but the target is not able to stop it from happening.',
        type: 'bullet',
      },
      {
        audio: '/audio/what-is-bullying/4.mp3',
        text: (
          <div>
            The student or group of students who are bullying others have more
            power than the student on the receiving end. Having more power can
            mean:
            <ul className='sub-bullets'>
              <li>Having more control of the situation</li>
              <li>Having more ability or physical strengths</li>
              <li>Having more friends or a greater influence with peers</li>
            </ul>
          </div>
        ),
        type: 'bullet',
      },
    ],
  },
  {
    url: 'what-isnt-bullying',
    component: <Intro4 />,
    section: sectionEnum.whatisBullying,
    locked: true,
    autoplay: autoplayEnum.ALL,
    audio: [
      {
        audio: '/audio/what-isnt-bullying/1.mp3',
        type: 'title',
      },
      {
        audio: '/audio/what-isnt-bullying/2.mp3',
        text: 'Usually, bullying isn’t negative or aggressive behavior that happens only once.',
        type: 'bullet',
      },
      {
        audio: '/audio/what-isnt-bullying/3.mp3',
        text: (
          <div>
            But, if the aggressive behavior is serious, it can be considered
            bullying even if it happens just one time.
            <ul className='sub-bullets'>
              <li>
                For example, if a student is pushed in front of a school bus, or
                if a student’s pants are pulled down in front of peers.
              </li>
            </ul>
          </div>
        ),
        type: 'bullet',
      },
      {
        audio: '/audio/what-isnt-bullying/4.mp3',
        text: (
          <div>
            Bullying isn’t when peers engage in play fight or teasing and all
            students involved can stop the behavior from happening if they don’t
            want it to continue.
            <ul className='sub-bullets'>
              <li>
                For example, it isn’t bullying if a student can walk away and
                not be bothered by the situation.
              </li>
            </ul>
          </div>
        ),
        type: 'bullet',
      },
      {
        audio: '/audio/what-isnt-bullying/5.mp3',
        text: 'Bullying isn’t when a student chooses not to hangout with someone for a good reason.',
        type: 'bullet',
      },
    ],
  },
  {
    url: 'knowledge-check-1-1',
    component: (
      <KnowledgeCheck
        prompt={knowledgeCheckMod1.prompt}
        initialState={knowledgeCheckMod1.questions}
        options={knowledgeCheckMod1.options}
        type={'trueOrFalse'}
      />
    ),
    section: sectionEnum.whatisBullying,
    locked: true,
    autoplay: autoplayEnum.ALL,
    audio: [
      {
        audio: '/audio/knowledge-check-1-1/subtitle.mp3',
        type: 'title',
      },
      {
        audio: '/audio/knowledge-check-1-1/q1.mp3',
        type: 'other',
      },
    ],
  },
  {
    url: 'negative-consequences',
    component: <Intro5 data={data.intro5} />,
    section: sectionEnum.whatisBullying,
    locked: true,
    autoplay: autoplayEnum.ALL,
    audio: [
      {
        audio: '/audio/negative-consequences/1.mp3',
        type: 'title',
      },
    ],
  },
  {
    url: 'students-who-bully',
    component: <StudentsWhoBully />,
    section: sectionEnum.whatisBullying,
    locked: true,
    autoplay: autoplayEnum.ALL,
    audio: [
      {
        audio: '/audio/students-who-bully/title.mp3',
        type: 'title',
      },
      {
        audio: '/audio/students-who-bully/bullet_1.mp3',
        text: 'Students who bully are not “bad people.”',
        type: 'bullet',
      },
      {
        audio: '/audio/students-who-bully/bullet_2.mp3',
        text: 'Deep down inside they do not feel confident or sometimes like who they are.',
        type: 'bullet',
      },
      {
        audio: '/audio/students-who-bully/bullet_3.mp3',
        text: 'They may have a difficult home life and a lot of time they have been bullied themselves by a family member or an older student.',
        type: 'bullet',
      },
      {
        audio: '/audio/students-who-bully/bullet_4.mp3',
        text: 'Because they don’t feel good about themselves, sometimes they bully so they can try to feel better.',
        type: 'bullet',
      },
      {
        audio: '/audio/students-who-bully/bullet_5.mp3',
        text: 'This does not make it okay for them to hurt others, but it helps us understand why students who bully may be treating others in a hurtful way.',
        type: 'bullet',
      },
    ],
  },
  {
    url: 'types-of-bullying',
    component: <BullyTypeSelector data={data.BullyTypeSelector} />,
    section: sectionEnum.whatisBullying,
    locked: true,
    autoplay: autoplayEnum.ALL,
    audio: [
      {
        audio: '/audio/types-of-bullying/1.mp3',
        type: 'title',
      },
    ],
  },
  {
    url: 'ranking-activity',
    component: <RankedDnd data={data} />,
    section: sectionEnum.whatisBullying,
    locked: true,
    autoplay: autoplayEnum.FIRST,
    audio: [
      {
        audio: '/audio/ranking-activity/1.mp3',
        type: 'title',
      },
      {
        audio: '/audio/ranking-activity/2.mp3',
        type: 'other',
      },
    ],
  },
  {
    url: 'write-it-out',
    component: <WriteItOut />,
    autoplay: autoplayEnum.ALL,
    section: sectionEnum.whatisBullying,
    locked: true,
    audio: [
      {
        audio: '/audio/write-it-out/audio.mp3',
        type: 'title',
      },
    ],
  },
  {
    url: 'knowledge-check-1-2',
    component: (
      <KnowledgeCheck
        prompt={knowledgeCheckMod12.prompt}
        initialState={knowledgeCheckMod12.questions}
        options={knowledgeCheckMod12.options}
        type={'dropdown'}
      />
    ),
    section: sectionEnum.whatisBullying,
    locked: true,
    autoplay: autoplayEnum.ALL,

    audio: [
      {
        audio: '/audio/knowledge-check-1-2/audio1.mp3',
        type: 'title',
      },
      {
        audio: '/audio/knowledge-check-1-2/q1.mp3',
        title: 'Verbal Bullying',
      },
    ],
  },
  {
    url: 'bullying-facts-2',
    component: <BullyFacts2 data={data.bullyFacts2} />,
    section: sectionEnum.bystanderRoles,
    locked: true,
    autoplay: autoplayEnum.ALL,
    audio: [
      {
        audio: '/audio/bullying-facts-2/teacher.mp3',
        type: 'title',
      },
    ],
  },
  {
    url: 'bystander-roles',
    component: <Selector />,
    section: sectionEnum.bystanderRoles,
    locked: true,
    autoplay: autoplayEnum.ALL,
    audio: [
      {
        audio: '/audio/bystander-roles/audio1.mp3',
        type: 'title',
      },
    ],
  },
  {
    url: 'estimation-activity',
    component: <ActivityModule data={data} />,
    section: sectionEnum.bystanderRoles,
    locked: true,
    autoplay: autoplayEnum.ALL,
    audio: [
      {
        audio: '/audio/estimation-activity/audio1.mp3',
        type: 'title',
      },
    ],
  },
  {
    url: 'why-defenders',
    component: <DefenderFacts />,
    section: sectionEnum.bystanderRoles,
    locked: true,
    autoplay: autoplayEnum.ALL,
    audio: [
      {
        audio: '/audio/why-defenders/1.mp3',
        type: 'title',
      },
      {
        audio: '/audio/why-defenders/2.mp3',
        text: 'Students can make a positive difference every time they act as a “defender” using a STAC strategy.',
        type: 'bullet',
      },
      {
        audio: '/audio/why-defenders/3.mp3',
        text: 'Students can stop bullying in as fast as 10 seconds!',
        type: 'bullet',
      },
      {
        audio: '/audio/why-defenders/4.mp3',
        text: 'Adults at school cannot do it all, and students can act differently when they are around peers and adults are not looking.',
        type: 'bullet',
      },
      {
        audio: '/audio/why-defenders/5.mp3',
        text: 'Students who are being bullied feel better when their peers encourage them, show kindness, listen, spend time with them, help them report the bullying, and help them get out of a tough situation.',
        type: 'bullet',
      },
      {
        audio: '/audio/why-defenders/6.mp3',
        text: 'These are the things you will learn to do as a “defender” using the STAC strategies.',
        type: 'bullet',
      },
    ],
  },
  // {
  //   url: 'whos-who',
  //   component: <WhosWho matchingActivity={matchingActivity1} />,
  //   section: sectionEnum.bystanderRoles,
  //   locked: true,
  // },
  // {
  //   url: 'knowledge-check-2',
  //   component: (
  //     <KnowledgeCheck
  //       prompt={knowledgeCheckMod2.prompt}
  //       initialState={knowledgeCheckMod2.questions}
  //       options={knowledgeCheckMod2.options}
  //       type={'dropdown'}
  //     />
  //   ),
  //   section: sectionEnum.bystanderRoles,
  //   locked: true,
  //   autoplay: autoplayEnum.ALL,
  //   audio: [
  //     {
  //       audio: '/audio/knowledge-check-2/1.mp3',
  //       type: 'title',
  //     },
  //   ],
  // },
  {
    url: 'stac-stategies',
    component: <STACTransition />,
    section: sectionEnum.stacStrategies,
    locked: true,
    autoplay: autoplayEnum.ALL,
    audio: [
      {
        audio: '/audio/stac-stategies/1.mp3',
        type: 'title',
      },
    ],
  },
  {
    url: 'stealing-the-show',
    component: <Steal />,
    section: sectionEnum.stacStrategies,
    locked: true,
    autoplay: autoplayEnum.ALL,
    audio: [
      {
        audio: '/audio/stealing-the-show/1.mp3',
        type: 'title',
      },
      {
        audio: '/audio/stealing-the-show/2.mp3',
        text: 'Defenders can use Stealing the Show in different ways. If you like being the center of attention and acting silly with your peers, you can use your sense of humor to interrupt the situation. You can walk up to your peers and be loud and tell your favorite joke or you can just start doing your favorite dance getting everyone to look at you.',
        type: 'bullet',
      },
      {
        audio: '/audio/stealing-the-show/3.mp3',
        text: 'If you are not comfortable being silly, you can interrupt the bullying by talking to the bully or the target. You can ask one of them if they saw a recent football or basketball game or you could talk about your favorite movie or music.',
        type: 'bullet',
      },
      {
        audio: '/audio/stealing-the-show/4.mp3',
        text: "Do not use Stealing the Show if you see a physical fight. You don't want to get involved and put your safety at risk.",
        type: 'bullet',
      },
      {
        audio: '/audio/stealing-the-show/5.mp3',
        text: "Do not use Stealing the Show when you see cyberbullying. You don't want to engage publicly and respond to the bully's post in any way.",
        type: 'bullet',
      },
    ],
  },
  {
    url: 'turning-it-over',
    component: <Turn />,
    section: sectionEnum.stacStrategies,
    locked: true,
    autoplay: autoplayEnum.ALL,
    audio: [
      {
        audio: '/audio/turning-it-over/1.mp3',
        type: 'title',
      },
      {
        audio: '/audio/turning-it-over/2.mp3',
        type: 'bullet',
        text: 'Think of the names of some adults at school that you feel like you could trust. These are adults you can turn a bullying situation over to.',
      },
      {
        audio: '/audio/turning-it-over/3.mp3',
        text: 'Always use Turning it Over when you see a physical fight between students. Your safety is very important. So, ask for help from an adult. Don’t get involved and try to stop a fight.',
        type: 'bullet',
      },
      {
        audio: '/audio/turning-it-over/4.mp3',
        text: 'Always use Turning it Over in the case of cyberbullying. Document what you see online and bring it to an adult at school that you trust. Take a screenshot of what you see right away before the bully has a chance to delete it. Do this every time you see cyberbullying because often adults need to build evidence before they can take formal action.',
        type: 'bullet',
      },
    ],
  },
  {
    url: 'accompanying-others',
    component: <Accompany />,
    section: sectionEnum.stacStrategies,
    locked: true,
    autoplay: autoplayEnum.ALL,
    audio: [
      {
        audio: '/audio/accompanying-others/1.mp3',
        type: 'title',
      },
      {
        audio: '/audio/accompanying-others/2.mp3',
        type: 'bullet',
        text: 'The defender communicates verbally or non-verbally to students who were targeted so that they know that they are not alone at school, they have a friend, and that what happened to them is not okay.',
      },
      {
        audio: '/audio/accompanying-others/3.mp3',
        text: 'You can do this either by letting the target know you saw what happened, and say something like, ”That was not cool. Are you okay?”, or you can just walk up to the target and invite the student to walk to class together or join you for lunch.',
        type: 'bullet',
      },
    ],
  },
  {
    url: 'coaching-compassion',
    component: <Coach />,
    section: sectionEnum.stacStrategies,
    locked: true,
    autoplay: autoplayEnum.ALL,
    audio: [
      {
        audio: '/audio/coaching-compassion/1.mp3',
        type: 'title',
      },
      {
        audio: '/audio/coaching-compassion/2.mp3',
        type: 'bullet',
        text: (
          <div>
            You should only use Coaching Compassion if you are:
            <ul className='sub-bullets'>
              <li>Older than the bully</li>
              <li>Good friends with the bully, or</li>
              <li>
                You have more status at school and you know the bully will
                listen to you.
              </li>
            </ul>
          </div>
        ),
      },
      {
        audio: '/audio/coaching-compassion/3.mp3',
        text: 'After the situation is over, go up to the bully and say something like, “Hey that was not cool.” You can then say, “Think about it, what if that was you?"',
        type: 'bullet',
      },
      {
        audio: '/audio/coaching-compassion/4.mp3',
        text: 'Students can use Stealing the Show to interrupt the bullying then follow it up with Coaching Compassion. ',
        type: 'bullet',
      },
    ],
  },
  // {
  //   url: 'knowledge-check-3',
  //   component: (
  //     <KnowledgeCheck
  //       prompt={knowledgeCheckMod4.prompt}
  //       initialState={knowledgeCheckMod4.questions}
  //       options={knowledgeCheckMod4.options}
  //       type={'trueOrFalse'}
  //       popUp={true}
  //     />
  //   ),
  //   section: sectionEnum.stacStrategies,
  //   locked: true,
  //   autoplay: autoplayEnum.ALL,
  //   audio: [
  //     {
  //       audio: '/audio/knowledge-check-3/1.mp3',
  //       type: 'title',
  //     },
  //   ],
  // },
  {
    url: 'all-avatars',
    component: <AllAvatars />,
    section: sectionEnum.wwyd,
    locked: true,
    autoplay: autoplayEnum.ALL,
    audio: [
      {
        audio: '/audio/all-avatars/1.mp3',
        type: 'title',
      },
    ],
  },
  {
    url: 'physical-intro',
    component: (
      <AvatarIntro
        title={'Physical Bullying'}
        bubbleText={
          "Let's practice using the STAC strategies when you see Physical Bullying."
        }
      />
    ),
    section: sectionEnum.wwyd,
    autoplay: autoplayEnum.ALL,
    locked: true,
    audio: [
      {
        audio: '/audio/physical-intro/1.mp3',
        type: 'title',
      },
    ],
  },
  {
    url: 'avatar-scenario-3',
    component: <AvatarThird />,
    section: sectionEnum.wwyd,
    autoplay: autoplayEnum.ALL,
    locked: true,
  },
  {
    url: 'verbal-intro',
    component: (
      <AvatarIntro
        title={'Verbal Bullying'}
        bubbleText={
          "Now let's practice using the STAC strategies when you see Verbal Bullying."
        }
      />
    ),
    section: sectionEnum.wwyd,
    autoplay: autoplayEnum.ALL,
    locked: true,
    audio: [
      {
        audio: '/audio/verbal-intro/1.mp3',
        type: 'title',
      },
    ],
  },
  {
    url: 'avatar-scenario-4',
    component: <AvatarFourth />,
    section: sectionEnum.wwyd,
    autoplay: autoplayEnum.ALL,
    locked: true,
  },
  {
    url: 'cyberbullying-intro',
    component: (
      <AvatarIntro
        title={'Cyberbullying'}
        bubbleText={
          "Next, let's practice using the STAC strategies when you see Cyberbullying."
        }
      />
    ),
    section: sectionEnum.wwyd,
    autoplay: autoplayEnum.ALL,
    locked: true,
    audio: [
      {
        audio: '/audio/cyber-intro/1.mp3',
        type: 'title',
      },
    ],
  },
  {
    url: 'avatar-scenario-1',
    component: <AvatarFirst />,
    section: sectionEnum.wwyd,
    locked: true,
    autoplay: autoplayEnum.ALL,
  },
  {
    url: 'relationship-intro',
    component: (
      <AvatarIntro
        title={'Relationship Bullying'}
        bubbleText={
          "Now let's practice using the STAC strategies when you see Relationship Bullying."
        }
      />
    ),
    section: sectionEnum.wwyd,
    autoplay: autoplayEnum.ALL,
    locked: true,
  },
  {
    url: 'avatar-scenario-2',
    component: <AvatarSecond />,
    section: sectionEnum.wwyd,
    locked: true,
    autoplay: autoplayEnum.ALL,
  },
  {
    url: 'end',
    component: <EndSlide />,
    section: sectionEnum.wwyd,
    locked: true,
    autoplay: autoplayEnum.ALL,
    audio: [
      {
        audio: '/audio/end/1.mp3',
        type: 'title',
      },
      {
        audio: '/audio/end/2.mp3',
        type: 'bullet',
        text: 'Stealing the Show – using humor or distraction to stop a bullying situation.',
      },
      {
        audio: '/audio/end/3.mp3',
        text: 'Turning it Over – reporting a bullying situation to an adult at school who you trust.',
        type: 'bullet',
      },
      {
        audio: '/audio/end/4.mp3',
        text: 'Accompanying Others – being a friend to a peer who was bullied.',
        type: 'bullet',
      },
      {
        audio: '/audio/end/5.mp3',
        text: 'Coaching Compassion – telling your peer who bullied someone to stop.',
        type: 'bullet',
      },
    ],
  },
  // {
  //   url: 'certification',
  //   component: <Certificate />,
  //   section: sectionEnum.wwyd,
  //   locked: false,
  // },
  {
    url: 'sapps-1',
    component: (
      <SAPPSWithTeacher
        bubbleText={
          'Before you finish the STAC main program, please complete this knowledge check'
        }
        options={sappSurvey.options}
        questions={[...sappSurvey.questions.slice(0, 4)]}
        prompt={sappSurvey.prompt}
      />
    ),
    section: sectionEnum.wwyd,
    locked: true,
    autoplay: autoplayEnum.ALL,
    audio: [
      {
        audio: '/audio/sapps/1.mp3',
        type: 'title',
      },
    ],
  },
  {
    url: 'sapps-2',
    component: (
      <SAPPS
        title={'Knowlege Check'}
        bubbleText={
          'Before you finish the STAC main program, please complete this knowledge check'
        }
        options={sappSurvey.options}
        questions={[...sappSurvey.questions.slice(4, 8)]}
        prompt={sappSurvey.prompt}
      />
    ),
    section: sectionEnum.wwyd,
    locked: true,
  },
  {
    url: 'sapps-3',
    component: (
      <SAPPS
        title={'Knowlege Check'}
        bubbleText={
          'Before you finish the STAC main program, please complete this knowledge check'
        }
        options={sappSurvey.options}
        questions={[...sappSurvey.questions.slice(8)]}
        prompt={sappSurvey.prompt}
      />
    ),
    section: sectionEnum.wwyd,
    locked: true,
  },
  {
    url: 'congrats',
    component: <Congrats />,
    section: sectionEnum.wwyd,
    autoplay: autoplayEnum.ALL,
    locked: false,
    audio: [
      {
        audio: '/audio/congrats/1.mp3',
        type: 'title',
      },
    ],
  },
  {
    url: 'certificate',
    component: <Certificate />,
    section: sectionEnum.wwyd,
    autoplay: autoplayEnum.ALL,
    locked: false,
  },
  // {
  //   url: 'sapps',
  //   component: <SAPPS />,
  //   section: sectionEnum.wwyd,
  //   locked: false,
  // },

  // '/all-avatars',
  // '/scenario1Page1',
  // '/scenario2Page1',
  // '/scenario3Page1',
  // '/scenario4Page1',
  // '/stac-certified',
];

export const countObj = countArray.reduce((obj, item) => {
  obj[item.url] = item;
  return obj;
}, {});

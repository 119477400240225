import Title from '../../shared/Title';
import styles from './Certificate.module.scss';
import React, { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';

const Example = () => {
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <div>
      <ComponentToPrint ref={componentRef} />
      <button onClick={handlePrint}></button>
    </div>
  );
};

export const ComponentToPrint = React.forwardRef((props, ref) => {
  return (
    <div className={styles.printWrapper} ref={ref}>
      <style type='text/css' media='print'>
        {
          '\
  @page { size: landscape; margin: 16px }\
'
        }
      </style>
      <img
        className={styles.certificate}
        alt='certificate'
        src={'/certificate.jpg'}
      ></img>
      <input
        placeholder='Enter your name here'
        className={styles.activitySubmit}
        type='text'
      />
    </div>
  );
});

function Certificate() {
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  return (
    <>
      <Title>
        <h1>Certificate</h1>
      </Title>
      <ComponentToPrint ref={componentRef} />
      <button className={styles.printButton} onClick={handlePrint}>
        Print Me
      </button>
    </>
  );
}

export default Certificate;

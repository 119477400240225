import React from 'react';
import { useAvatar } from '../../../hooks/AvatarContext';
import AvatarSlider from './AvatarSlider';
const AvatarFourth = () => {
  const { selectedAvatar } = useAvatar();
  console.log(selectedAvatar, 'selecedAvatar');

  const baseSlides = [
    {
      title: 'Main Program 1',
      image: {
        url: '/avatars/scenes/scene4/b1/story/1.png',
      },
      type: 'main',
      audio: [
        {
          audio: '/audio/avatar-scenario-4/base/1.mp3',
        },
      ],
    },
    {
      title: 'Main Program 2',
      image: {
        url: '/avatars/scenes/scene4/b1/story/2.png',
      },
      type: 'main',
      audio: [
        {
          audio: '/audio/avatar-scenario-4/base/2.mp3',
        },
      ],
    },
    {
      title: 'Main Program 2',
      image: {
        url: '/avatars/scenes/scene4/b1/story/3.png',
      },
      type: 'main',
      audio: [
        {
          audio: '/audio/avatar-scenario-4/base/3.mp3',
        },
      ],
    },
    {
      title: 'Main Program 2',
      image: {
        url: '/avatars/scenes/scene4/b1/story/4.png',
      },
      type: 'main',
      audio: [
        {
          audio: '/audio/avatar-scenario-4/base/4.mp3',
        },
      ],
    },
    {
      title: 'Main Program 2',
      image: {
        url: '/avatars/scenes/scene4/b1/story/5.png',
      },
      type: 'wwyd',
    },
  ];

  const responseOptions = [
    {
      title: 'Stealing The Show',
      completed: false,
      active: false,
      slides: [
        {
          title: 'Turning It Over',
          image: {
            url: '/avatars/scenes/scene4/b1/steal/1.png',
          },
          type: 'turn',
          audio: [
            {
              audio: '/audio/avatar-scenario-4/steal/1.mp3',
            },
          ],
        },
        {
          title: 'Turning It Over',
          image: {
            url: '/avatars/scenes/scene4/b1/steal/2.png',
          },
          type: 'turn',
          audio: [
            {
              audio: '/audio/avatar-scenario-4/steal/2.mp3',
            },
          ],
        },
        {
          title: 'Main Program 2',
          image: {
            url: '/avatars/scenes/scene4/teacher.png',
          },
          text: 'Excellent choice! Using Stealing the Show works well for students who are outgoing and like being the center of attention. Another option is to start a conversation with the students who are making fun of Cameron and talk about something you have in common. For example, say “Hey are you going to the football game this week?',
          type: 'end',
          audio: [
            {
              audio: '/audio/avatar-scenario-4/steal/3.mp3',
            },
          ],
        },
      ],
    },
    {
      title: 'Turning It Over',
      completed: false,
      active: false,
      slides: [
        {
          title: 'Turning It Over',
          image: {
            url: '/avatars/scenes/scene4/b1/turn/1.png',
          },
          type: 'turn',
          audio: [
            {
              audio: '/audio/avatar-scenario-4/turn/1.mp3',
            },
          ],
        },
        {
          title: 'Turning It Over',
          image: {
            url: '/avatars/scenes/scene4/b1/turn/2.png',
          },
          type: 'turn',
          audio: [
            {
              audio: '/audio/avatar-scenario-4/turn/2.mp3',
            },
          ],
        },
        {
          title: 'Main Program 2',
          image: {
            url: '/avatars/scenes/scene4/teacher.png',
          },
          text: 'Good choice! You can always let an adult at school know when you see bullying behaviors. Now, click on the other strategies to see how you could use those strategies in this situation.',
          type: 'end',
          audio: [
            {
              audio: '/audio/avatar-scenario-4/turn/3.mp3',
            },
          ],
        },
      ],
    },
    {
      completed: false,
      active: false,
      title: 'Accompanying Others ',
      slides: [
        {
          title: 'Accompanying Others',
          image: {
            url: '/avatars/scenes/scene4/b1/accompany/1.png',
          },
          type: 'turn',
          audio: [
            {
              audio: '/audio/avatar-scenario-4/accompany/1.mp3',
            },
          ],
        },
        {
          title: 'Accompanying Others',
          image: {
            url: '/avatars/scenes/scene4/b1/accompany/2.png',
          },
          type: 'turn',
          audio: [
            {
              audio: '/audio/avatar-scenario-4/accompany/2.mp3',
            },
          ],
        },
        {
          title: 'Main Program 2',
          image: {
            url: '/avatars/scenes/scene4/teacher.png',
          },
          text: 'Great choice! Accompanying Others lets your peers who are bullied know that  you care about them and you think that what happened isn’t okay. You can also use Accompanying Others by walking with Cameron to his class. Just being there without talking about what happened can feel more comfortable for some students. ',
          type: 'end',
          audio: [
            {
              audio: '/audio/avatar-scenario-4/accompany/3.mp3',
            },
          ],
        },
      ],
    },
    {
      title: 'Coaching Compassion',
      completed: false,
      active: false,
      slides: [
        {
          title: 'Coach',
          image: {
            url: '/avatars/scenes/scene4/b1/coach/1.png',
          },
          type: 'turn',
          audio: [
            {
              audio: '/audio/avatar-scenario-4/coach/1.mp3',
            },
          ],
        },
        {
          title: 'Coach',
          image: {
            url: '/avatars/scenes/scene4/b1/coach/2.png',
          },
          type: 'turn',
          audio: [
            {
              audio: '/audio/avatar-scenario-4/coach/2.mp3',
            },
          ],
        },
        {
          title: 'Main Program 2',
          image: {
            url: '/avatars/scenes/scene4/teacher.png',
          },
          text: 'If you are older, friends with the students who are giving Cameron a hard time, or think the students who are bullying Cameron will listen to you and respect you, Coaching Compassion is an excellent choice. You can say something quick, like “Stop!” or “Cut it out” and often other students will listen.',
          type: 'end',
          audio: [
            {
              audio: '/audio/avatar-scenario-4/coach/3.mp3',
            },
          ],
        },
      ],
    },
  ];

  return (
    <div>
      <AvatarSlider
        storySlides={baseSlides}
        responseOptions={responseOptions}
        title={'Verbal Bullying'}
      />
    </div>
  );
};

export default AvatarFourth;

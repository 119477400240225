import React from 'react';
import Title from '../../shared/Title';
import Trainer from '../../shared/Trainer';

const EndSlide = () => {
  return (
    <div>
      <Title>
        <h1>Great Job!</h1>
      </Title>
      <div className='banner'>
        <div className='wrappp'>
          <Trainer teacher={3} />
        </div>
        <p>
          Great job! Remember that you are making a positive difference in
          students’ lives when you stand up for them and act as a defender. What
          you do, does make a difference!
        </p>
      </div>
    </div>
  );
};

export default EndSlide;

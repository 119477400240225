import React from 'react';

const UsernameSVG = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      version='1.1'
      viewBox='112 96 288 320'
    >
      <g transform='scale(16)'>
        <path d='M19.626,16.77c1.439,-1.098 2.374,-2.825 2.374,-4.77c0,-3.309 -2.691,-6 -6,-6c-3.309,0 -6,2.691 -6,6c0,1.945 0.935,3.672 2.374,4.77c-3.161,1.397 -5.374,4.558 -5.374,8.23v1h18v-1c0,-3.672 -2.213,-6.833 -5.374,-8.23Zm-7.626,-4.77c0,-2.206 1.794,-4 4,-4c2.206,0 4,1.794 4,4c0,2.206 -1.794,4 -4,4c-2.206,0 -4,-1.794 -4,-4Zm-2.929,12c0.487,-3.388 3.408,-6 6.929,-6c3.521,0 6.442,2.612 6.929,6h-13.858Z' />
      </g>
    </svg>
  );
};

export default UsernameSVG;

import React, { useMemo } from 'react';
import { useStore } from './zustand';

const useAudioAndText = () => {
  const {
    currentIndex,
    audioArray,
    bulletIndexStart,
    bulletIndexEnd,
    allSoundsPlayed,
    direction,
  } = useStore((store) => {
    return {
      audioArray: store.audioArray,
      currentIndex: store.currentIndex,
      bulletIndexStart: store.audioArray.findIndex(
        (item) => item.type === 'bullet'
      ),
      bulletIndexEnd: store.audioArray.findLastIndex(
        (item) => item.type === 'bullet'
      ),
      allSoundsPlayed: store.allSoundsPlayed,
      direction: store.direction,
    };
  });

  const bulletPointArray = useMemo(() => {
    return audioArray
      .filter((item) => item.type === 'bullet')
      .map((item) => item.text);
  }, [audioArray]);

  return {
    currentIndex,
    bulletPointArray,
    bulletIndexStart,
    bulletIndexEnd,
    allSoundsPlayed,
    direction,
  };
};

export default useAudioAndText;

import { countArray } from './components/pages/mainprogram/mainProgramItems';

export const getCurrentPageInfo = (url) => {
  const index = countArray.findIndex((item) => item.url === `${url}`);
  const info = countArray[index];
  return {
    info,
    index,
  };
};

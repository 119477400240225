import { Booster, Wrapper } from './Booster';
import React from 'react';
/* eslint-disable react-hooks/exhaustive-deps */
import './booster.scss';
import BoosterIntro from './BoosterIntro2';
import STACStrategies from './STACStrategies';
import PracticeVideoModule from './1-1';
import EndSlide from './EndSlide';
import Tracker from './Tracker';
import WhoToTurnTo3 from '../intro/WhoToTurnTo3';
import BullyTypeSelector from '../bullying/bullyTypeSelector';
import { autoplayEnum } from '../../../hooks/zustand';
import ReviewBullyingTypes from './ReviewBullyingTypes';
import KnowledgeCheck from '../intro/KnowledgeCheck';

//rename

const selectionItems1 = [
  {
    title: 'Stealing the Show',
    teacher: {
      text: 'Stealing the Show is not a good choice for this situation. When you see physical bullying, don’t get directly involved. We want you to stay safe. Getting involved could make the situation worse and unintendedly bring you into it.',
      imgUrl: '/booster/teacher.gif',
    },
  },
  {
    title: 'Turning It Over',
    defender: {
      text: 'The defender finds a trusted teacher. The teacher asks the defender, “Hey! How’s it going?”. The defender tells the teacher about seeing physical bullying. “I really need to let you know that there’s some serious physical bullying going on. I’ve seen it several times now.”',
      imgUrl: '/booster/defender.gif',
    },
    teacher: {
      text: 'Great choice of strategy! Using Turning It Over is the first thing you should do when you see physical bullying. It is a great idea to tell a trusted adult right away.',
      imgUrl: '/booster/teacher.gif',
    },
  },
  {
    title: 'Accompanying Others',
    defender: {
      text: 'The defender tells the target, “Hey. I’m headed to a class near yours. Mind if I walk with you? You okay? Those guys were rude.”',
      imgUrl: '/booster/defender.gif',
    },
    teacher: {
      text: 'Accompanying Others is a good choice! After the situation is over, you can go up to your peer who was targeted and let your peer know that you saw what happened and that it was not okay. Offer to go with the student to report it to a safe adult at school.',
      imgUrl: '/booster/teacher.gif',
    },
  },
  {
    title: 'Coaching Compassion ',
    defender: {
      text: 'The defender tells the group of bullies, “Hey, leave him alone! Think about what it would be like if you were in his shoes.”',
      imgUrl: '/booster/defender.gif',
    },
    teacher: {
      text: 'If you are older, friends with the students who are engaging in the bullying or think the students will listen to you and respect you, then Coaching Compassion is a good choice. You can also just say something quick, like “Don’t do that. It’s not cool”, and often students will listen.',
      imgUrl: '/booster/teacher.gif',
    },
  },
];

const selectionItems2 = [
  {
    title: 'Stealing the Show',
    defender: {
      text: 'The defender walks up to the group of girls and interrupts them by doing a silly dance and making a funny sound while dancing.',
      imgUrl: '/booster/defender.gif',
    },
    teacher: {
      text: 'Great choice! Stealing the Show is a good strategy for students who are outgoing and aren’t afraid to be the center of attention. By Stealing the Show, you are interrupting the bullying situation and moving your peers’ attention away from the bully and the target placing it on you, but in a safe way. Another way you could interrupt the situation is to say something like,, “Hey did you see the latest episode of the show we are all watching last night? It was really good! I can’t wait for next week.”',
      imgUrl: '/booster/teacher.gif',
    },
  },
  {
    title: 'Turning It Over',
    defender: {
      text: 'The defender tells  a trusted adult, “Mr. Martinez, could you please ask those girls to stop putting that other girl down? I see this happen to her all the time.”',
      imgUrl: '/booster/defender.gif',
    },
    teacher: {
      text: 'You can always tell an adult when you see bullying and you don’t feel comfortable getting directly involved.',
      imgUrl: '/booster/teacher.gif',
    },
  },
  {
    title: 'Accompanying Others',
    defender: {
      text: 'The defender walks up to the student who was bullied and says, “I heard what those girls were saying and that was really rude. Are you okay? I will walk to class with you. I am going the same way you are.”',
      imgUrl: '/booster/defender.gif',
    },
    teacher: {
      text: 'This is an excellent choice to help students who are targeted feel like there is someone else at school who are about them. This can be easy to do and it can make a big difference iIt can help your peer who was targeted not feel so alone and feel like someone else cares.',
      imgUrl: '/booster/teacher.gif',
    },
  },
  {
    title: 'Coaching Compassion ',
    defender: {
      text: 'The defender walks up to the group of girls and says “Hey, what you did was not okay. It’s not cool to make fun of people like that. Don’t do that.',
      imgUrl: '/booster/defender.gif',
    },
    teacher: {
      text: 'Coaching Compassion is an excellent choice if you are older, friends with, or think the students who are bullying will listen and respect you. You can also just say something quick, like “stop” or “leave her alone” during the situation and often students will listen to you.',
      imgUrl: '/booster/teacher.gif',
    },
  },
];

const selectionItems3 = [
  {
    title: 'Stealing the Show',
    defender: {
      text: 'The defender interrupts the girl spreading the rumor at the bus stop by pointing and saying to the group, “Look! There is a really cute dog over there."',
      imgUrl: '/booster/g1_1_animated.gif',
    },
    teacher: {
      text: 'Great choice! Stealing the Show is a good strategy for students who are outgoing and aren’t afraid to be the center of attention. By Stealing the Show, you are interrupting the bullying situation and moving your peers’ attention away from the bully and the target and placing it on you, but in a safe manner. Another thing you could do to interrupt the group from spreading a rumor is to tell a joke or act goofy by doing a silly dance.. Now check out other strategies so that you can have options!',
      imgUrl: '/booster/teacher.gif',
    },
  },
  {
    title: 'Turning It Over',
    defender: {
      text: 'The defender walks up to an adult by the bus stop and lets the adult know what is going on by saying, “I wanted to let you know what’s going on. A group of girls is planning to spread a rumor about someone. I am not sure what to do, but I know it’s not okay.”',
      imgUrl: '/booster/g1_1_animated.gif',
    },
    teacher: {
      text: 'Good idea to tell an adult. You can always ask for adult help when you see something going on that you think is not okay and can be hurtful to one of your peers. Now, click on the other strategies to see how you could use those strategies in this situation.',
      imgUrl: '/booster/teacher.gif',
    },
  },
  {
    title: 'Accompanying Others',
    defender: {
      text: 'The defender walks up to the girl who had the rumor spread about her (she looks upset like she knows what happened), and says, “I know it’s not true that you cheated on the test. I will tell people that it’s not true.”',
      imgUrl: '/booster/g1_1_animated.gif',
    },
    teacher: {
      text: 'Accompanying Others is a great choice! When you express concern for your peers, they will probably feel like someone at school cares about them, and it can make a really big difference. It does not take much to help someone out by being a friend or even just friendly to them. Now check out the other strategies so that you know how to use other strategies, too! Stealing the Show and Coaching Compassion are also great options.',
      imgUrl: '/booster/teacher.gif',
    },
  },
  {
    title: 'Coaching Compassion ',
    defender: {
      text: 'The defender speaks to the girls spreading the rumor by saying, “I’ve had rumors spread about me and it was really hard. Rumors can make people feel really bad. So, let’s not do this.”',
      imgUrl: '/booster/g1_1_animated.gif',
    },
    teacher: {
      text: 'If you are older, friends with the girls who are going to spread the rumor, or think those girls will listen to you and respect you, Coaching Compassion is an excellent choice. You can also just say something quick like “Don’t do that. It’s not okay”, and often students will listen. Now, click on the other strategies to see how you could use those, too.',
      imgUrl: '/booster/teacher.gif',
    },
  },
];

const bully = [
  {
    title: 'Physical Bullying',
    content:
      'Hurting someone’s body or destroying or breaking their possessions.',
    examples:
      'Examples: hitting, kicking, pinching, spitting, tripping someone, pushing, or taking or breaking someone’s things.',
    imgURL: 'physicalbullying.png',
    audio: '/audio/types-of-bullying/physical.mp3',
  },
  {
    title: 'Verbal Bullying',
    content: 'Saying or writing mean things about someone.',
    examples:
      'Examples: name-calling, teasing, making inappropriate comments, taunting someone, or threatening to cause harm to a peer.',
    imgURL: 'verbalbullying.png',
    audio: '/audio/types-of-bullying/verbal.mp3',
  },
  {
    title: 'Relationship Bullying',
    content: 'Intentionally hurting someone’s reputation or relationships.',
    examples:
      'Examples: leaving someone out on purpose, telling other students not to be friends with someone, spreading rumors, or intentionally embarrassing or humiliating someone in public.',
    imgURL: 'relationshipbullying.png',
    audio: '/audio/types-of-bullying/relationship.mp3',
  },
  {
    title: 'Cyberbullying',
    content: 'Using electronic devices and technology to hurt someone.',
    examples:
      'Examples: use text messages or emails to write hurtful messages, spread rumors on social media, post embarrassing pictures or videos to humiliate another person, or create a fake profiles to post mean things about people.',
    imgURL: 'cyberbullying.png',
    audio: '/audio/types-of-bullying/cyber.mp3',
  },
];

const selectionItems4 = [
  {
    title: 'Stealing the Show',
    teacher: {
      text: 'When you see cyberbullying, you do not want to engage by Stealing the Show. It can make the situation worse to give it more  attention. However, do use a different strategy. Turning it Over is the best choice when you see cyberbullying!',
      imgUrl: '/booster/teacher.gif',
    },
  },
  {
    title: 'Turning It Over',
    defender: {
      text: 'The defender takes a screenshot of the post, and shows it to a  trusted adult at school the next day.',
      imgUrl: '/booster/defender.gif',
    },
    teacher: {
      text: 'This is a great choice of strategy! Letting a trusted adult know when you see cyberbullying is the best action you can take. Even when bullying happens online, the adults at school can help! Go ahead and click on the other strategies to see how they play out. You could follow this one up with Accompanying Others or Coaching Compassion.',
      imgUrl: '/booster/teacher.gif',
    },
  },
  {
    title: 'Accompanying Others',
    defender: {
      text: 'The defender crosses paths with the student who was made fun of (and the student looks sad); the defender makes an effort to say hi and smile.',
      imgUrl: '/booster/defender.gif',
    },
    teacher: {
      text: 'Good choice. By Accompanying Others and smiling and being friendly to the student who was targeted, you can help her feel more uplifted and like there is someone who is nice and cares about her at school. It can help her feel less alone. Remember to also always use Turning it Over when you see cyberbullying. Now click on other strategies, starting with Turning it Over, to see how to use them.',
      imgUrl: '/booster/teacher.gif',
    },
  },
  {
    title: 'Coaching Compassion ',
    defender: {
      text: 'The defender goes up to the students who are filming and says, “That’s not cool. You should delete those posts. Also, it can get you in big trouble.”',
      imgUrl: '/booster/defender.gif',
    },
    teacher: {
      text: 'This is a good choice! Remember to only use Coaching Compassion if you are older, friends with the bullies or think they will respect you. If you are able to stop those girls from making a video and posting it, that would be an excellent way to act as a defender and make a positive difference at your school and for your peer who is being targeted. Remember to also always use Turning it Over when you see cyberbullying. Now, click on the other strategies to see how to use them.',
      imgUrl: '/booster/teacher.gif',
    },
  },
];

const knowledgeCheckMod4 = {
  prompt: 'Mark each item as true or false.',
  options: ['true', 'false'],
  questions: [
    {
      type: 'true',
      description:
        'A defender can Steal the Show by distracting the bully by acting silly or starting a conversation.',
      answer: 'not answered',
    },
    {
      type: 'false',
      description:
        'Defenders should use Stealing the Show and get involved if they see a physical fight.',
      answer: 'not answered',
    },
    {
      type: 'true',
      description:
        'Defenders can use Accompanying Others by inviting a classmate who was bullied to have lunch together.',
      answer: 'not answered',
    },
    {
      type: 'false',
      description:
        'It is appropriate to Turn Over a bullying situation to another student at school.',
      answer: 'not answered',
    },
    {
      type: 'true',
      description:
        'Defenders could use Coaching Compassion if they are older or good friends with the bully or think the bully will respect them.',
      answer: 'not answered',
    },
  ],
};

const routesArray = [
  {
    url: 'intro',
    component: <BoosterIntro />,
    locked: true,
  },
  {
    url: 'review-types',
    component: <ReviewBullyingTypes />,
    locked: true,
  },
  {
    url: 'stac-strategies',
    component: <STACStrategies />,
    locked: true,
  },
  {
    url: 'knowledge-check',
    locked: true,
    component: (
      <KnowledgeCheck
        prompt={knowledgeCheckMod4.prompt}
        initialState={knowledgeCheckMod4.questions}
        options={knowledgeCheckMod4.options}
        type={'trueOrFalse'}
        popUp={true}
      />
    ),
  },
  {
    url: 'tracker',
    component: <Tracker />,
    locked: true,
  },
  {
    url: 'scenario-1',
    component: (
      <PracticeVideoModule
        selectionItems={selectionItems1}
        title={'Practice Time 1'}
        videoSrc={'/booster/bus'}
        defenderImg={'/booster/defender-hallway.gif'}
        prize={1}
      />
    ),
    locked: true,
  },
  {
    url: 'scenario-2',
    component: (
      <PracticeVideoModule
        selectionItems={selectionItems2}
        title={'Practice Time 2'}
        videoSrc={'/booster/pettyGirls'}
        defenderImg={'/booster/defender-petty-girls.gif'}
        prize={2}
      />
    ),
    locked: true,
  },
  {
    url: 'scenario-3',
    component: (
      <PracticeVideoModule
        selectionItems={selectionItems3}
        title={'Practice Time 3'}
        videoSrc={'/booster/cheater'}
        defenderImg={'/booster/g1_1_animated.gif'}
        prize={1}
      />
    ),
    locked: true,
  },
  {
    url: 'scenario-4',
    component: (
      <PracticeVideoModule
        selectionItems={selectionItems4}
        title={'Practice Time 4'}
        videoSrc={'/booster/faceFood'}
        defenderImg={'/booster/b1_1_animated.gif'}
        prize={2}
      />
    ),
    locked: true,
  },
  {
    url: 'end',
    component: <EndSlide />,
    locked: false,
  },
];

function BoosterOne() {
  return (
    <Wrapper>
      <Booster path='booster' routesArray={routesArray} />
    </Wrapper>
  );
}

export default BoosterOne;

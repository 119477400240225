import React from 'react';
import Title from '../../shared/Title';
import { FadeInBulletPoints } from '../intro/FadeInBulletPoints';

const STACStrategies = () => {
  return (
    <>
      <Title isModal>
        <h1>The STAC Strategies </h1>
      </Title>
      <div className='bullet-img-wrap'>
        <FadeInBulletPoints
          listItems={[
            <>
              <span className='semibold'>Stealing the Show</span> using humor or
              distraction to stop a bullying situation.
            </>,
            <>
              <span className='semibold'>Turning it Over</span> reporting a
              bullying situation to an adult at school who you trust.
            </>,
            <>
              <span className='semibold'>Accompanying Others</span> being a
              friend to a peer who was bullied.
            </>,
            <>
              <span className='semibold'>Coaching Compassion</span> telling your
              peer who bullied someone to stop. Remember to only use Coaching
              Compassion if you are older, good friends with the bully, or think
              the bully will listen to you and respect you.
            </>,
          ]}
        />
        <div className='img-wrap'>
          <img alt='bullying' src='/boosterSlideTwo.png' />
        </div>
      </div>
    </>
  );
};

export default STACStrategies;

import React from 'react';
import Button from './buttons.jsx';
import CrumbBox from './CrumbBox.jsx';
import { useLocked } from '../hooks/ContinueProvider.jsx';
import { countArray as c2 } from '../pages/mainprogram/mainProgramItems.jsx';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min.js';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min.js';

function Footer({ activePageNumer, totalPageNumber, handleBack, handleNext }) {
  const { locked } = useLocked();

  return (
    <div className='footer'>
      <Button type='navigationLeft' text='BACK' click={handleBack} />
      {totalPageNumber && (
        <CrumbBox currentPage={activePageNumer} lastPage={totalPageNumber} />
      )}
      <Button
        type='navigation'
        text='NEXT'
        click={handleNext}
        inActive={!locked ? false : true}
      />
    </div>
  );
}

export default Footer;

import React, { useEffect, useRef, useState } from 'react';
import { gsap } from 'gsap';
import { useLocked } from '../../hooks/ContinueProvider';

export const FadeInBulletPoints = ({ listItems, ulClasses }) => {
  const ulRef = useRef(null);

  const { unlockProgram } = useLocked();

  const [index, setIndex] = useState(0);

  useEffect(() => {
    // Separate animation for the first child
    gsap.fromTo(
      ulRef.current.children[0],
      { autoAlpha: 0, y: -20 },
      { autoAlpha: 1, y: 0, delay: 0.25 }
    );

    // Staggered animation for the rest of the children
    gsap.fromTo(
      Array.from(ulRef.current.children).slice(1),
      { autoAlpha: 0, y: -20 },
      {
        autoAlpha: 1,
        y: 0,
        stagger: 2,
        delay: 2,
        onComplete: () => {
          // Call the onEnd callback after all animations are done
          unlockProgram();
        },
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ul ref={ulRef} className={`introQuery ${ulClasses} `}>
      {listItems.map((item, index) => {
        return (
          <li
            key={index}
            className='fade-in-bullet-point'
            style={{ opacity: 0 }}
          >
            {item}
          </li>
        );
      })}
    </ul>
  );
};

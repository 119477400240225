import React from 'react';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import LeftArrow from './LeftArrow';
import RightArrow from './RightArrow';
import '../../stylesheets/main.scss';
import { useStore } from '../../hooks/zustand';

export const CustomLink = ({ path, children }) => {
  return (
    <Link to={path}>
      <div className='customButton navigation'>{children}</div>
    </Link>
  );
};
function CustomButton(props) {
  const { allSoundsPlayed } = useStore((state) => {
    return {
      isPlaying: state.isPlaying,
      allSoundsPlayed: state.allSoundsPlayed,
    };
  });

  console.log('i am here', props.type, props.hasBeenClicked);

  // I am a button selector componenet I accept props to determine what I look like and what I do
  // props accepted are text, type, click, link, styleName
  let button = <Button variant='primary'>Primary</Button>;
  switch (props.type) {
    case 'next':
      button = (
        <Button onClick={props.click} variant='dark'>
          Next
        </Button>
      );
      break;
    case 'options':
      button = (
        <button className='optionsClose' onClick={props.click}>
          {props.text + 'hiu'}
        </button>
      );
      break;
    case 'optionsClose':
      if (!allSoundsPlayed) {
        button = null;
        break;
      }
      button = (
        <button className='optionsClose' onClick={props.click}>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 50 50'
            width='50px'
            height='50px'
          >
            <line
              fill='none'
              stroke='#000000'
              strokeWidth='4'
              strokeMiterlimit='10'
              x1='7.741'
              y1='7.741'
              x2='42.359'
              y2='42.359'
            />
            <line
              fill='none'
              stroke='#000000'
              strokeWidth='4'
              strokeMiterlimit='10'
              x1='42.258'
              y1='7.742'
              x2='7.618'
              y2='42.382'
            />
          </svg>
        </button>
      );
      break;
    case 'options+':
      props.onMouseEnter
        ? (button = (
            <button
              disabled={props.disabled}
              onMouseEnter={props.onMouseEnter}
              onMouseLeave={props.onMouseLeave}
              className={`options ${props.styleName} `}
              onClick={props.click}
            >
              <div>
                {(props.text = 'hiu')}
                {props.hasBeenClicked && (
                  <img
                    alt='checkmark'
                    className='checkmark'
                    src={'/booster/check.png'}
                  />
                )}
              </div>
            </button>
          ))
        : (button = (
            <button
              disabled={props.disabled}
              className={`options ${props.hasBeenClicked ? 'completed' : ''} 
              ${props.styleName}
                `}
              onClick={props.click}
            >
              {props.text}
              {props.hasBeenClicked && (
                <img
                  alt='checkmark'
                  className='checkmark'
                  src={'/booster/check.png'}
                />
              )}
            </button>
          ));
      break;
    case 'optionsBystand':
      button = (
        <button className='activityButton' onClick={props.click}>
          {props.text}
        </button>
      );
      break;
    case 'thinButton':
      button = (
        <button className='thinButton' onClick={props.click}>
          {props.text}
        </button>
      );
      break;
    case 'leftArrow':
      button = (
        <div onClick={props.click}>
          <img
            alt='right arrow'
            className='leftArrow'
            src={require('../../assets/images/SVG/leftArrowOrange.svg')}
          />
        </div>
      );
      break;
    case 'rightArrow':
      button = (
        <div onClick={props.click}>
          <img
            alt='left arrow'
            className='rightArrow'
            src={require('../../assets/images/SVG/rightArrowOrange.svg')}
          />
        </div>
      );
      break;

    case 'navigation':
      button = (
        <button
          className={`customButton navigation ${props.inActive && 'inactive'}`}
          onClick={props.click}
        >
          <span>{props.text}</span>
          <RightArrow />
        </button>
      );
      break;
    case 'navigationLeft':
      button = (
        <button className='customButton navigation' onClick={props.click}>
          <LeftArrow />
          <span>{props.text}</span>
        </button>
      );
      break;
    case 'navigation+':
      button = (
        <button
          className={`${props.styleName} customButton navigation `}
          onClick={props.click}
        >
          {props.text}
        </button>
      );
      break;
    case 'backtoselect':
      button = (
        <Link to={props.link}>
          <button className='customButton navigation' onClick={props.click}>
            {props.text}
          </button>
        </Link>
      );
      break;

    case 'inactiveNext':
      button = (
        <div className='inactive'>
          <button className='customButton navigation'>{props.text}</button>
        </div>
      );
      break;
    case 'activitySubmit':
      button = (
        <button onClick={props.click} className='activitySubmit'>
          {' '}
          {props.text}
        </button>
      );
      break;

    case 'customModalClose':
      button = (
        <button onClick={props.click} className='CustomModalClose'>
          {' '}
          {props.children}
        </button>
      );
      break;
    default:
      button = (
        <button className='customButton' onClick={props.click}>
          {props.text}
        </button>
      );
      break;
  }

  return <div>{button}</div>;
}
export default CustomButton;

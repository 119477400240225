import React from 'react';

const Lock = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      id='Layer_2'
      data-name='Layer 2'
      viewBox='0 0 4573.8 6333.46'
    >
      <defs>
        <style></style>
      </defs>
      <g id='Ebene_1' data-name='Ebene 1'>
        <path d='m2242.09,1.44c95.68-4.56,189.4,2.19,276,13,257.15,32.09,475.92,108.82,662,210,254.33,138.3,455.74,318.46,623,540,82.88,109.78,154.31,233.5,213,369,88.27,203.81,149.19,432.56,156,716,2.26,94.26,0,191.77,0,291,0,193.66.02,385.57,0,581,0,3.75-1.02,9.41,0,13,2,7.02,2.06,5.07,7,7,12.63,4.94,26.04,7.07,38,11,132.01,43.38,232.58,127.39,295,244,22.62,42.26,40.5,90.25,51,145,10.86,56.64,9.98,120.12,10,186,.21,780.55-.21,1573.17,0,2351,.02,67.06,2.86,130.61-3,193-11,117.2-56.95,207.34-115,277-59.85,71.82-136.44,126.56-233,159-48.73,16.37-105.58,26-170,26-1171.68.05-2357.57-.05-3526,0-64.52,0-121.85-8.93-171-25-97.39-31.83-173.83-86.28-234-158-58.48-69.71-104.08-160.3-117-275-6.9-61.23-2.99-127.09-3-193-.07-776.64.07-1566.27,0-2350,0-66.07-1.4-130.64,9-187,19.39-105.12,68.64-193.36,133-259,65.03-66.33,141.41-117.47,247-144,7.4-1.86,9.87,1.45,12-10,3.84-20.65,0-48,0-72-.03-165.34.03-336.77,0-500-.03-192.34-5.25-385.43,20-551,50.17-329.05,170.14-590.11,328-815,154.43-220,358.41-408.89,599-544,185.75-104.32,384.38-184.9,632-225,81.36-13.17,166.43-19.5,261-24Zm1322,2081c0-143.95,2.93-290.29-17-412-30.03-183.39-95.46-334.65-178-468-108.68-175.59-256.04-316.35-437-424-88.9-52.88-188.64-97.54-302-129-139.56-38.74-319.4-56.52-485-38-218.21,24.41-395.53,95.67-544,191-169.54,108.86-305.63,250.22-412,427-51.81,86.1-94.7,183.41-127,292-32.4,108.91-53.64,232.39-52,368,3.27,270.02-2.45,552.33,0,838h2554c0-217.75,0-430.53,0-645Zm-856,2201c51.61-90.08,82.65-219.77,52-355-25.41-112.1-86.56-200.21-167-266-75.26-61.56-188.63-114.35-329-107-129.95,6.81-225.11,56.23-300,122-74.13,65.11-131.9,155.64-154,269-25.91,132.9,8.85,258.5,60,344,53.31,89.11,129.74,151.58,225,198v1018h384v-1019c99.19-46.65,176.21-111.86,229-204Z' />
      </g>
    </svg>
  );
};

export default Lock;

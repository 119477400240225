import React from 'react';
import { autoplayEnum, useStore } from '../../../hooks/zustand.js';
import './login.scss';
import logo from '../../../assets/images/SVG/logo2.png';
import { useHistory } from 'react-router-dom';
import UsernameSVG from './usernameSVG.jsx';
import LockSVG from './lock.jsx';

import { Link } from 'react-router-dom/cjs/react-router-dom.min';

function Login() {
  const { load, eject } = useStore((state) => {
    return {
      load: state.load,
      eject: state.eject,
    };
  });

  const history = useHistory();

  const handleSubmit = (e) => {
    //go to overview page
    e.preventDefault();
    history.push('/start');
    eject();
    load(
      [
        {
          audio: '/audio/start/audio1.mp3',
          type: 'title',
        },
      ],
      autoplayEnum.ALL
    );
  };

  return (
    <div className='auth-wrapper'>
      <section>
        <div className='login-wrap'>
          <div className='inner-wrap'>
            <div className='center'>
              <img src={logo} alt='logo' className='logo' />
            </div>
            <form onSubmit={handleSubmit}>
              <label>
                <p>Username</p>
                <div className='input-wrap'>
                  <div className='login-svg'>
                    <UsernameSVG />
                  </div>
                  <input
                    required
                    name='username'
                    type='text'
                    placeholder='Type your username'
                  />
                </div>
              </label>
              <label>
                <p>Password</p>
                <div className='input-wrap'>
                  <div className='login-svg'>
                    <LockSVG />
                  </div>
                  <input
                    required
                    name='username'
                    type='text'
                    placeholder='Type your password'
                  />
                </div>
                <a href='#' className='forgot'>
                  Forgot your password?
                </a>
              </label>
              <div className='button-wrap'>
                <button
                  onClick={() => {
                    eject();
                    load('/audio/start/audio1.mp3', autoplayEnum.FIRST);
                  }}
                  className='button'
                  type='submit'
                >
                  Login
                </button>
              </div>
            </form>
            <p className='sign-up-thing'>
              Or <a href='#'>Sign Up Here</a>
            </p>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Login;

import React from 'react';
import './App.scss';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import ScrollToTop from './components/shared/scrollToTop.jsx';
import 'bootstrap/dist/css/bootstrap.min.css';
import './stylesheets/main.scss';
import Start from './components/pages/start/start';
import Login from './components/pages/login/login';
import { MainProgramWrapper } from './components/shared/MainProgram';
import AudioProvider from './hooks/audioProvider.js';
import BoosterOne from './components/pages/booster/BoosterOne.jsx';
import BoosterTwo from './components/pages/booster/BoosterTwo.jsx';
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';
import { LockedProvider } from './components/hooks/ContinueProvider.jsx';
import FireWorksProvider, { useFireworks } from './hooks/FireWorksProvider.js';
import SoloTracker from './components/pages/booster/SoloTracker.jsx';

function App() {
  return (
    <div className='reactBody'>
      <Router>
        <WrappedApp />
      </Router>
    </div>
  );
}

function WrappedApp() {
  return (
    <AudioProvider>
      <LockedProvider>
        <FireWorksProvider>
          <Explosion />
          <ScrollToTop />
          <Switch>
            <Route exact path='/start' component={Start} />
            <Route path='/login' component={Login} />
            <Route
              path='/main-program/:activity'
              component={MainProgramWrapper}
            />
            <Route path='/booster/:section' component={BoosterOne} />
            <Route path='/booster-two/:section' component={BoosterTwo} />
            <Route path='/tracker' component={SoloTracker} />
            <Redirect from='/' to='/login' />
          </Switch>
          {/* <PageContent /> */}
        </FireWorksProvider>
      </LockedProvider>
    </AudioProvider>
  );
}

function Explosion() {
  const { explosion } = useFireworks();

  console.log(explosion, 'explosion');
  if (explosion) {
    return (
      <div className='firework-wrapper'>
        <img src='/glitter.gif' alt='celebration' className='fireworks' />
      </div>
    );
  }
  return null;
}

export default App;

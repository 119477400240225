import React, { Fragment, useEffect, useRef } from 'react';
import { Route } from 'react-router-dom';
import '../../stylesheets/main.scss';
import Header from '../shared/Header.jsx';
import Footer from './Footer.jsx';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { countArray as c2 } from '../pages/mainprogram/mainProgramItems.jsx';
import { LockedProvider } from '../hooks/ContinueProvider';
import { AvatarProvider } from '../../hooks/AvatarContext';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import FireWorksProvider from '../../hooks/FireWorksProvider';
import AudioProvider from '../../hooks/audioProvider.js';
import { autoplayEnum } from '../../hooks/zustand.js';
import { useLocked } from '../hooks/ContinueProvider';
import { useFireworks } from '../../hooks/FireWorksProvider';
import { getCurrentPageInfo } from '../../utils.js';
import { useStore, directextEnum } from '../../hooks/zustand.js';

const RouteWrapper = ({ component: Component, ...rest }) => {
  return (
    <div className='activityContainer'>
      {c2.map((item) => (
        <Route
          key={item.url}
          path={`/main-program/${item.url}`}
          render={() => item.component}
        />
      ))}
    </div>
  );
};

function MainSection() {
  return (
    <div className='mainSection'>
      <div className='activityShadow'>
        <RouteWrapper />
      </div>
    </div>
  );
}

export function MainProgramWrapper() {
  return (
    <AvatarProvider>
      <MainProgram />
    </AvatarProvider>
  );
}

function MainProgram() {
  const { activity } = useParams();
  const history = useHistory();
  const currentPage = getCurrentPageInfo(activity);
  const currentPageAudio = currentPage.info.audio;
  const { lockProgram } = useLocked();

  const { load, setDirection, eject, isLoaded } = useStore((state) => {
    return {
      load: state.load,
      eject: state.eject,
      isLoaded: state.audioArray.length > 0,
      setDirection: state.setDirection,
    };
  });

  const handleBack = () => {
    eject();
    const prevPage = c2[currentPage.index - 1];
    if (currentPage.index > 0) {
      setDirection('backward');
      history.push(`/main-program/${prevPage.url}`);
      if (prevPage.audio) {
        load(prevPage.audio, prevPage.autoplay);
      }
    } else {
      history.push('/start');
    }
  };

  // const ejectAndLoad = useCallback(() => {
  //   eject();
  //   const currentPage = getCurrentPageInfo(activity);
  //   if (currentPageAudio) {
  //     console.log('loading', currentPage.info);
  //     load(currentPageAudio, currentPage.info.autoplay);
  //   }
  // });

  useEffect(() => {
    const currentPage = getCurrentPageInfo(activity);

    if (currentPage.info.locked) {
      lockProgram();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activity]);

  useEffect(() => {
    if (currentPage.info.audio && !isLoaded) {
      load(currentPageAudio, currentPage.info.autoplay);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoaded]);

  useEffect(() => {
    window.addEventListener('popstate', eject);

    return () => {
      eject();
      window.removeEventListener('popstate', eject);
    };
  }, [eject]);

  //make eject get called on clicking back button

  const handleNext = (isFirstSlide = false) => {
    eject();
    const nextPage = c2[currentPage.index + 1];
    if (currentPage.index < c2.length - 1) {
      setDirection('forward');
      if (nextPage.audio) {
        load(nextPage.audio, nextPage.autoplay);
      }
      history.push(`/main-program/${nextPage.url}`);
    } else {
      history.push('/start');
    }
  };

  return (
    <Fragment>
      <Header section={currentPage.info.section} />
      <MainSection />
      <Footer
        activePageNumer={currentPage.index + 1}
        totalPageNumber={c2.length}
        c2={c2}
        handleBack={handleBack}
        handleNext={handleNext}
      />
    </Fragment>
  );
}

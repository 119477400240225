/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo } from 'react';
import { Navbar } from 'reactstrap';
import './booster.scss';
import Footer from '../../shared/Footer';
import { LockedProvider, useLocked } from '../../hooks/ContinueProvider';
import { Route } from 'react-router-dom/cjs/react-router-dom.min';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import NonMainHeader from '../start/NonMainHeader';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import FireWorksProvider from '../../../hooks/FireWorksProvider';

export const Wrapper = ({ children }) => {
  return (
    <>
      <NonMainHeader />
      {children}
    </>
  );
};

export const Booster = ({ routesArray, path }) => {
  const { section } = useParams();
  const history = useHistory();
  const currentPage = useMemo(
    () => routesArray.findIndex((item) => item.url === `${section}`),
    [section]
  );

  const { lockProgram } = useLocked();

  useEffect(() => {
    const shouldLockPage = routesArray[currentPage].locked;

    console.log(currentPage);
    if (shouldLockPage) {
      lockProgram();
    }
  }, [section]);

  const handleBack = () => {
    if (currentPage > 0) {
      history.push(`/${path}/${routesArray[currentPage - 1].url}`);
    } else {
      history.push('/start');
    }
  };

  const handleNext = () => {
    if (currentPage < routesArray.length - 1) {
      history.push(`/${path}/${routesArray[currentPage + 1].url}`);
    } else {
      history.push('/start');
    }
  };

  return (
    <>
      <div className='mainSection'>
        <div className='activityShadow'>
          <div className='activityContainer'>
            {routesArray.map((item, index) => (
              <Route
                key={index}
                path={`/${path}/${item.url}`}
                render={() => item.component}
              />
            ))}
          </div>
        </div>
      </div>
      <Footer
        activePageNumer={currentPage + 1}
        totalPageNumber={routesArray.length}
        c2={routesArray}
        handleBack={handleBack}
        handleNext={handleNext}
      />
    </>
  );
};

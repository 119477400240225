import React from 'react';
import Questionaire from '../../shared/Questionaire';
import { useEffect } from 'react';
import Title from '../../shared/Title';
import { gsap } from 'gsap';
import SpeechBubble from '../../shared/SpeechBubble';
import Trainer from '../../shared/Trainer';
import { FadeInBulletPoints } from '../intro/FadeInBulletPoints';
import { useLocked } from '../../hooks/ContinueProvider';

function ReviewBullyingTypes() {
  useEffect(() => {
    gsap.from('.intro2Box', {
      bottom: '-100em',
      duration: 0.5,
    });
    gsap.from('.mainNavBubble', {
      left: '1000px',
    });
  }, []);

  const { unlockProgram } = useLocked();

  return (
    <>
      <Title>
        <h1>Bullying Types</h1>
      </Title>
      <div className='card-container'>
        <div className='flex-boost'>
          <div className='make-bigger'>
            <div className='teacherContainer'>
              <div className='mainNavBubble'>
                <SpeechBubble tween={{}}>
                  <div>There are four different types of bullying.</div>
                </SpeechBubble>
              </div>
              <Trainer teacher={2} />
            </div>
          </div>
          <div className='teo'>
            <FadeInBulletPoints
              listItems={[
                <>
                  <b>Physical bullying</b> – hurting someone’s body or
                  destroying or breaking their possessions.
                </>,
                <>
                  <b>Verbal bullying</b> – saying or writing mean things about
                  someone.
                </>,
                <>
                  <b>Relationship bullying </b> – intentionally hurting
                  someone’s reputation or relationships.
                </>,
                <>
                  <b>Cyberbullying</b> – using electronic devices and technology
                  to hurt someone.
                </>,
              ]}
            />
          </div>
        </div>
      </div>
    </>
  );
}
export default ReviewBullyingTypes;

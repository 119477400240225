import React, { useCallback, useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import useAudioAndText from '../../../hooks/useAudioAndText';
import { directextEnum } from '../../../hooks/zustand';

//add prop type checking
import PropTypes from 'prop-types';

//add audio

export const FadeInBulletPointsWithAudio = ({ ulClasses }) => {
  const ulRef = useRef(null);

  const {
    currentIndex,
    bulletPointArray,
    bulletIndexStart,
    bulletIndexEnd,
    allSoundsPlayed,
    direction,
  } = useAudioAndText();

  //pop in bulet points based on index
  useEffect(() => {
    if (
      currentIndex <= bulletIndexEnd &&
      currentIndex >= bulletIndexStart &&
      !allSoundsPlayed &&
      direction !== directextEnum.BACKWARD
    ) {
      gsap.fromTo(
        ulRef.current.children[currentIndex - bulletIndexStart],
        { opacity: 0 },
        { opacity: 1, duration: 1 }
      );
    }
  }, [
    allSoundsPlayed,
    bulletIndexEnd,
    bulletIndexStart,
    currentIndex,
    direction,
  ]);

  return (
    <>
      <ul ref={ulRef} className={`introQuery ${ulClasses} `}>
        {bulletPointArray.map((item, index) => {
          return (
            <li
              key={index}
              className='fade-in-bullet-point'
              style={
                direction !== directextEnum.BACKWARD
                  ? { opacity: 0 }
                  : { opacity: 1 }
              }
            >
              {item}
            </li>
          );
        })}
      </ul>
    </>
  );
};

import React from 'react';
import Questionaire from '../../shared/Questionaire';
import { useEffect } from 'react';
import Title from '../../shared/Title';
import { gsap } from 'gsap';
import SpeechBubble from '../../shared/SpeechBubble';
import Trainer from '../../shared/Trainer';
import { FadeInBulletPoints } from '../intro/FadeInBulletPoints';
import { useLocked } from '../../hooks/ContinueProvider';

function BoosterIntro() {
  useEffect(() => {
    gsap.from('.intro2Box', {
      bottom: '-100em',
      duration: 0.5,
    });
    gsap.from('.mainNavBubble', {
      left: '1000px',
    });
  }, []);

  const { unlockProgram } = useLocked();

  return (
    <>
      <Title isModal>
        <h1>Welcome Back!</h1>
      </Title>
      <div className='card-container'>
        <div className='flex-boost'>
          <div className='make-bigger'>
            <div className='teacherContainer'>
              <div className='mainNavBubble'>
                <SpeechBubble tween={{}}>
                  <div>Welcome back to the STAC Training!</div>
                </SpeechBubble>
              </div>
              <Trainer teacher={2} />
            </div>
          </div>
          <div className='teo'>
            <p className='today'>Today You Will</p>
            <FadeInBulletPoints
              listItems={[
                'Review the 4 STAC strategies.',
                'Practice using each of the strategies so you can become more comfortable intervening when you see bullying at school or online.',
                'Complete an activity where you track the types of bullying situations you saw and what strategies you used to act as a defender since last time you worked on this online program.',
              ]}
            />
          </div>
        </div>
      </div>
    </>
  );
}
export default BoosterIntro;

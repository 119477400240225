import React from 'react';
import { getCurrentPageInfo } from '../../utils';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import PlayButton from '../../assets/images/audio/PlayButton';
import PauseButton from '../../assets/images/audio/PauseButton';
import ReplayButton from '../../assets/images/audio/ReplayButton';
import { useStore } from '../../hooks/zustand';
import { autoplayEnum } from '../../hooks/zustand';

function AudioControls() {
  const location = useLocation();
  const paramArray = location.pathname.split('/');
  const activity = paramArray[paramArray.length - 1];
  const currentPage = getCurrentPageInfo(activity);

  const { replayAll, isPlaying, play, pause, shouldReplay, isLoaded, load } =
    useStore((state) => ({
      replayAll: state.replayAll,
      isLoaded: state.audioArray.length > 0,
      isPlaying: state.isPlaying,
      load: state.load,
      play: state.play,
      pause: state.pause,
      shouldReplay: state.endOfAudio,
    }));

  if (!isLoaded) {
    return null;
  }
  if (shouldReplay) {
    return (
      <button
        id='audioButton'
        className='audioButton'
        onClick={() => {
          replayAll();
        }}
        aria-label='Replay Audio'
      >
        <ReplayButton />
      </button>
    );
  } else if (!isPlaying) {
    return (
      <button
        id='audioButton'
        className='audioButton'
        aria-label='Play Audio'
        onClick={() => {
          if (!isLoaded) {
            load(currentPage.info.audio, autoplayEnum.AfterFirst);
          } else {
            play();
          }
        }}
      >
        <PlayButton />
      </button>
    );
  } else if (isPlaying) {
    return (
      <button
        id='audioButton'
        className='audioButton'
        aria-label='Pause Audio'
        onClick={() => {
          pause();
        }}
      >
        <PauseButton />
      </button>
    );
  }
}

export default AudioControls;

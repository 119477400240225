import React from 'react';
import Title from '../../shared/Title';
import { Quiz } from '../intro/KnowledgeCheck';
import SpeechBubble from '../../shared/SpeechBubble';
import Trainer from '../../shared/Trainer';
import styles from './sapps.module.scss';

const sappSurvey = {
  prompt:
    'Please read each statement and circle the answer that matches how much you agree with each statement. There are no right or wrong answers so please share your honest feelings.  ',
  options: ['I Totally Agree', 'I Agree', 'I Disagree', 'I Totally Disagree'],
  questions: [
    {
      description: '1. I know what verbal bullying looks like.',
      answer: 'not answered',
    },

    {
      description: '2. I know what relationship bullying looks like.',
      answer: 'not answered',
    },
    {
      description: '3. I know what physical bullying looks like.',
      answer: 'not answered',
    },
    {
      description: '4. I know what cyberbullying looks like.',
      answer: 'not answered',
    },
    {
      description:
        '5. I know how to use humor to get attention away from the student being bullied. ',
      answer: 'not answered',
    },
    {
      description:
        '6. I know how to ask for help from an adult and report bullying at my school.',
      answer: 'not answered',
    },
    {
      description: '7. I know how to reach out to the student being bullied.',
      answer: 'not answered',
    },
    {
      description:
        '8. I know how to gently confront the bully and offer suggestions to help him or her develop empathy for the target of bullying. ',
      answer: 'not answered',
    },
    {
      description:
        '9. I feel confident in my ability to do something helpful to decrease bullying at my school.',
      answer: 'not answered',
    },
    {
      description:
        '10. I feel comfortable being an advocate to stop bullying at my school.',
      answer: 'not answered',
    },
    {
      description:
        '11. I feel like I can make a positive difference against bullying at my school.',
      answer: 'not answered',
    },
  ],
};

export const SAPPSWithTeacher = ({
  prompt,
  options,
  questions,
  bubbleText,
}) => {
  return (
    <>
      <Title>
        <h1>Knowledge Check</h1>
      </Title>
      <div className={styles.flexSapps}>
        <div className='teacherContainer'>
          <div className='mainNavBubble'>
            <SpeechBubble tween={{}}>
              <div>{bubbleText}</div>
            </SpeechBubble>
          </div>
          <Trainer teacher={4} />
        </div>
        <Quiz
          noWrongAnswers
          prompt={prompt}
          initialState={questions}
          options={options}
          type={'dropdown'}
        />
      </div>
    </>
  );
};

export const SAPPS = ({ prompt, options, questions, title }) => {
  return (
    <>
      {title && (
        <Title>
          <h1>{title}</h1>
        </Title>
      )}
      <Quiz
        noWrongAnswers
        prompt={prompt}
        initialState={questions}
        options={options}
        type={'dropdown'}
      />
    </>
  );
};

export default SAPPS;

import React from 'react';
import { useAvatar } from '../../../hooks/AvatarContext';
import AvatarSlider from './AvatarSlider';
const AvatarThird = () => {
  const { selectedAvatar } = useAvatar();

  const baseSlides = [
    {
      title: 'Main Program 2',
      image: {
        url: '/avatars/scenes/scene3/b1/story/2.png',
      },
      type: 'main',
      audio: [
        {
          audio: '/audio/avatar-scenario-3/base/1.mp3',
        },
      ],
    },
    {
      title: 'Main Program 2',
      image: {
        url: '/avatars/scenes/scene3/b1/story/3.png',
      },
      type: 'wwyd',
    },
  ];

  const responseOptions = [
    {
      title: 'Stealing The Show',
      completed: false,
      active: false,
      slides: [
        {
          title: 'Main Program 2',
          image: {
            url: '/avatars/scenes/scene3/teacher.png',
          },
          audio: [
            {
              audio: '/audio/avatar-scenario-3/steal/1.mp3',
            },
          ],
          text: 'When you see physical bullying don’t get directly involved because it could make the situation worse. Turning it Over is the best strategy to use here because you can intervene while keeping yourself safe from getting in the middle of a physical fight.',
          type: 'end',
        },
      ],
    },
    {
      title: 'Turning It Over',
      completed: false,
      active: false,
      slides: [
        {
          title: 'Turning It Over',
          image: {
            url: '/avatars/scenes/scene3/b1/turn/1.png',
          },
          type: 'turn',
          audio: [
            {
              audio: '/audio/avatar-scenario-3/turn/1.mp3',
            },
          ],
        },
        {
          title: 'Turning It Over',
          image: {
            url: '/avatars/scenes/scene3/b1/turn/2.png',
          },
          type: 'turn',
          audio: [
            {
              audio: '/audio/avatar-scenario-3/turn/2.mp3',
            },
          ],
        },
        {
          title: 'Turning It Over',
          image: {
            url: '/avatars/scenes/scene3/b1/turn/3.png',
          },
          type: 'turn',
          audio: [
            {
              audio: '/audio/avatar-scenario-3/turn/3.mp3',
            },
          ],
        },
        {
          title: 'Main Program 2',
          image: {
            url: '/avatars/scenes/scene3/teacher.png',
          },
          text: 'Great choice! Using Turning It Over is the first thing you should do when you see physical bullying. It is a great idea to tell an adult right away.',
          type: 'end',
          audio: [
            {
              audio: '/audio/avatar-scenario-3/turn/4.mp3',
            },
          ],
        },
      ],
    },
    {
      completed: false,
      active: false,
      title: 'Accompanying Others ',
      slides: [
        {
          title: 'Accompanying Others',
          image: {
            url: '/avatars/scenes/scene3/b1/accompany/1.png',
          },
          type: 'turn',
          audio: [
            {
              audio: '/audio/avatar-scenario-3/accompany/1.mp3',
            },
          ],
        },
        {
          title: 'Accompanying Others',
          image: {
            url: '/avatars/scenes/scene3/b1/accompany/2.png',
          },
          type: 'turn',
          audio: [
            {
              audio: '/audio/avatar-scenario-3/accompany/2.mp3',
            },
          ],
        },
        {
          title: 'Main Program 2',
          image: {
            url: '/avatars/scenes/scene3/teacher.png',
          },
          text: 'Good choice! After the situation is over, you can go up to your peer and let him know that you saw what happened and it was not okay. Offer to go with him to report it to a safe adult at school.',
          type: 'end',
          audio: [
            {
              audio: '/audio/avatar-scenario-3/accompany/3.mp3',
            },
          ],
        },
      ],
    },
    {
      title: 'Coaching Compassion',
      completed: false,
      active: false,
      slides: [
        {
          title: 'Coach',
          image: {
            url: '/avatars/scenes/scene3/b1/coach/1.png',
          },
          type: 'turn',
          audio: [
            {
              audio: '/audio/avatar-scenario-3/coach/1.mp3',
            },
          ],
        },
        {
          title: 'Coach',
          image: {
            url: '/avatars/scenes/scene3/b1/coach/2.png',
          },
          type: 'turn',
          audio: [
            {
              audio: '/audio/avatar-scenario-3/coach/2.mp3',
            },
          ],
        },
        {
          title: 'Main Program 2',
          image: {
            url: '/avatars/scenes/scene3/teacher.png',
          },
          text: 'If you are older, friends with the students who are doing the bullying, or think the bullies will listen to you and respect you, Coaching Compassion is a great choice! You can just say something quick, like, “Stop!” or “Don’t do that, it’s not cool.” Often that’s all it takes. Many students will listen and stop what they are doing.',
          type: 'end',
          audio: [
            {
              audio: '/audio/avatar-scenario-3/coach/3.mp3',
            },
          ],
        },
      ],
    },
  ];

  return (
    <div>
      <AvatarSlider
        storySlides={baseSlides}
        responseOptions={responseOptions}
        title={'Physical Bullying'}
      />
    </div>
  );
};

export default AvatarThird;
